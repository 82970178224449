<template>
    <pagination title="Payment Vouchers" :details-open="viewUser" :response="response" :entities="entities" :filterAction="filterAction" header-colour-class="bg-transparent" :withAltViewIcon="true" @altViewClicked="altViewClicked" activeClass="bg-v3-pink bg-opacity-20" filtersActiveClass="text-v3-pink border-v3-pink focus:text-v3-pink focus:border-v3-pink">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Reference
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Created At</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Added By</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Operative</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Contract</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Status</th>
        </template>
        <template v-slot:items>
            <tr v-for="entity in entities" class="cursor-pointer" @click="openDetails(entity)" :class="{'bg-v3-pink bg-opacity-20':openId !== null && entity.entity.properties.get('id') === openId}">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{entity.entity.properties.get('reference')}}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    {{ entity.entity.properties.get('created_at')}}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{ entity.entity.properties.get('added_by_name') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{entity.entity.properties.get('subcontractor_name')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{entity.entity.properties.get('contract_details')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{entity.entity.properties.get('status')}}
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import Siren from "super-siren";
let config = {
    rounding: Math.floor
}
dayjs.extend(duration);
dayjs.extend(relativeTime, config);
export default {
  name: 'VouchersPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          openId: null
      }
    },
    components: {FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
          this.openId = entity.entity.properties.get('id');
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            console.log(selfLink);
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
            this.openId = null;
        }
    }
}
</script>
