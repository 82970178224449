<template>
  <div class="bg-v3-pink bg-opacity-20 rounded-full h-fit dark:bg-v3-gray-50/20">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
          <div>
            <navigation-link name="Payment Vouchers" :is-active="isCurrentPage('/payment-vouchers')" :open="panelOpen" href="/payment-vouchers" activeClass="text-v3-white-100 bg-v3-pink dark:text-v3-gray-900" additional-classes="text-v3-pink">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M4 4a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2a2 2 0 0 1-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 1-2-2a2 2 0 0 1 2-2V6a2 2 0 0 0-2-2zm11.5 3L17 8.5L8.5 17L7 15.5zm-6.69.04c.98 0 1.77.79 1.77 1.77a1.77 1.77 0 0 1-1.77 1.77c-.98 0-1.77-.79-1.77-1.77a1.77 1.77 0 0 1 1.77-1.77m6.38 6.38c.98 0 1.77.79 1.77 1.77a1.77 1.77 0 0 1-1.77 1.77c-.98 0-1.77-.79-1.77-1.77a1.77 1.77 0 0 1 1.77-1.77"/></svg>

              </template>
            </navigation-link>

          </div>
            <div class="pt-12">
                <navigation-link name="My Dash" :open="panelOpen" href="/"  activeClass="text-v3-pink" additional-classes="text-v3-pink ring-2 ring-current">
                    <template v-slot:icon>
                        <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                        </svg>
                    </template>
                </navigation-link>
            </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'VoucherNavigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: true
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
    },

}
</script>
